(function(f){if(typeof exports==="object"&&typeof module!=="undefined"){module.exports=f()}else if(typeof define==="function"&&define.amd){define([],f)}else{var g;if(typeof window!=="undefined"){g=window}else if(typeof global!=="undefined"){g=global}else if(typeof self!=="undefined"){g=self}else{g=this}g.jsonViewer = f()}})(function(){var define,module,exports;return (function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
'use strict';

var $;
var document;
var isArray;
var reLink = /^(?:http(?:s)?|mailto|tel):(?:\/)*/i;

(function resolveDependencies() {
    if ( ( typeof window === 'undefined' ) && ( typeof module !== 'undefined' ) && module.exports) {
            var win;
            $ = require( 'jquery' )( win = new ( require( 'jsdom' ).JSDOM)('<!DOCTYPE html>').window );
            document = win.document;
    } else {
            $ = window.$;
            document = window.document;
    }
    isArray = ( ( typeof angular === 'undefined' ) ? Array: angular ).isArray;
})();

/**
 * The default "complex object" renderer. It creates new HTML
 * structure underneath the element pointed by the container argument.
 * @method renderNode
 * @param {String} label A string containing data description, typically the data key at parent object level.
 * @param {Object} data The complex object to prepare HTML elements for.
 * @param {Object} container The parent (jQuery wrapped) hosting the new HTML structure.
 * @param {Array.<String>} path The location of the data within the complete data collection being rendered.
 * @param {Object|null|undefined} context The metadata associated with the object rendered. May specify extra container or label CSS classes, indentation adjustments.
 * @returns {Object} The new container to continue the rendering within. jQuery wrapped HTML DOM node.
 */
function renderNode( label, data, container, path, context ) {
    var el;
    context = context || {};
    container/*.append(
        $( document.createElement( 'pre' ) ).text( path.join( '.' ) ) )*/.append(
        el= $( document.createElement( 'div' ) ).append(
                $( document.createElement( 'label' ) ).text(
                    label ).addClass(
                        [ ].concat(
                            context.labelClasses ).join( ' ' )
                    )
              ).addClass( [ ].concat(
                    context.containerClasses,
                    '-json-view-level-' + ( path.length - ( context.levelNudge || 0 ) ),
                    '-bbds-schema-key-' + path[ path.length - 1 ] ).join( ' ' ) )
        );
    delete context.containerClasses;
    delete context.labelClasses;
    el.append(
            el = $( document.createElement( 'div' ) ) );
    return el;
}

/**
 * Invoked once the "complex object" fully renders. Will not be called
 * for simple data, like strings, numbers, etc. For full definition of
 * "simple data", consult the extractValue function implementation.
 * @method endRenderNode
 * @param {String} label A string containing data description, typically the data key at parent object level.
 * @param {Object} data The complex object to prepare HTML elements for.
 * @param {Object} container The parent (jQuery wrapped) hosting the just created HTML structure.
 * @param {Array.<String>} path The location of the data within the complete data collection being rendered.
 * @param {Object|null|undefined} context The metadata associated with the object rendered. May specify extra container or label CSS classes, indentation adjustments.
 */
function endRenderNode( label, data, container, path, context ) {
    // nothing currently
}

/**
 * Renders a "simple object" or value type.
 * @method renderValue
 * @param {String} label A string containing data description, typically the data key at parent object level.
 * @param {Number|String|Date|Boolean} data The value to render.
 * @param {String|null|undefined} formattedData The Well-formatted representation of the value to render. This is the value actually outputted in the HTML, whenever specified.
 * @param {Object} container The parent (jQuery wrapped) hosting the value.
 * @param {Array.<String>} path The location of the data within the complete data collection being rendered.
 * @param {Object|null|undefined} context The metadata associated with the object rendered.
 * @returns {Object} The jQuery wrapped HTML DOM node where the value has been rendered.
 */
function renderValue( label, data, formattedData, container, path, context ) {
    var el;
    data = ( formattedData || String( data ) );
    if ( reLink.test( data ) ) {
        container.append(
            ( el = $( document.createElement( 'div' ) ) ).append( 
                $( '<a/>',  { 
                                href: data,
                                text: data.replace( reLink, formatLinkToText ),
                                target: ( ( ( data || '' ).slice( 0, 3 ) === 'tel' ) ? '_self': 'viewer_link' )
                            } ) )
        ).addClass( '-json-view-value' );
    } else {
        container.append(
            el = $( document.createElement( 'div' ) )
                    .text( data )
        ).addClass( '-json-view-value' );
    }
    return el;
}
function formatLinkToText( part ) {
    return  ( ( part || '' ).toLowerCase().slice( 0, 4 ) === 'http' )  ?
        part: '';
}

/**
 * Allows discovering known value types in the data type hint.
 * @private
 */
var reTypeHint = /Date|Boolean|Number|String|Phone/;
/**
 * Reduces a "complex object" to formatted value type, whenever the conversion is possible.
 * @private
 * @method extractValue
 * @param {Object|Number|String|Date|Boolean} data The "complex object" to reduce.
 * @param {String|null|undefined} hint What may be contained within data of type string.
 * @param {Object|null|undefined} context The metadata associated with the object rendered.
 * @returns {String|undefined} The string the data reduces to, or undefined if no value type can be extracted.
 */
function extractValue( data, hint, context ) {
    var c;
    switch (  ( ( typeof data === 'object' ) && ( data !== null ) &&
                ( c = data.constructor ) &&
                c.name ) ||
              ( ( typeof data === 'string' ) &&
                ( ( String( hint || '' ).match( reTypeHint ) || [] )[ 0 ] || 'String' ) ) ||
              '' )
    {

        case 'Date':
            return ( context && context.friendlyIsoTime ) ?
                     context.friendlyIsoTime( data ): data;

        case 'Phone':
            if ( !reLink.test( data ) ) {
                data = 'tel:' + String( data );
            }

        case 'Boolean':
        case 'Number':
        case 'String':
            return String( data );

        default:
            if ( ( typeof data === 'object' ) ) {
                if ( data === null){
                    return String( null );
                }
                if (Object.keys(data).length ===0) {
                    return String(' ');
                }
            }
            return undefined;
    }
}

/**
 * The library entry point, recursively traversing the data to render
 * @method render
 * @param {Number|String|Date|Boolean} data The value to render.
 * @param {Object|undefined|null} container The parent (jQuery wrapped) hosting the HTML DOM fragment render result.
 * @param {Array.<String>|null|undefined} path The location of the data within the complete data collection being rendered.
 * @param {Object.<String, Function>|null|undefined} customRenderers The renders to use. If no specific renderer has been passed, the default renderer from the core is being invoked.
 * @param {Object|null|undefined} context The metadata associated with the object rendered. Keys "siblings" and "dataOverride" are library reserved and should be avoided at top level render calls. Within context.friendlyIsoTime you may pass data formatting function.
 * @returns {Object} The containerm hosting the render results 
 */
function render( data, container, path, customRenderers, context ) {
    if ( !path ) {
        path = [];
    }
    if ( !customRenderers ) {
        customRenderers = {};
    }
    if ( !context ) {
        context = {};
    }
    if ( !container ) {
        container = document.createElement( "json-viewer" );
    }
    container = $( container );
    delete context.dataOverride;
    var val = extractValue( data, path[ path.length - 1 ], context );
    if ( ( val === undefined ) && ( data !== undefined ) ) {
        var index = ( !isArray( data ) ) ?
                        Object.keys( data ): data;
        var siblings = {
            index: index,
            data: data,
            offset: -1
        };
        for( var i = 0, l = index.length, it, el, key; i < l; i ++ ) {
            siblings.offset = i;
            it = ( ( data === index ) ? index[ i ]: data[ index[ i ] ] );
            key = ( ( data === index ) ? i : index[ i ] );
            path.push( key );
            context.siblings = siblings;
            if ( el = ( customRenderers.renderNode || renderNode )( key, it, container, path, context ) ) {
                render( ( context.dataOverride || it ), el, path, customRenderers, context );
            }
            // restore the context siblings and invoke endRender
            context.siblings = siblings;
            ( customRenderers.endRenderNode || endRenderNode )( key, it, container, path, context );
            path.pop();
        }
    } else {
        ( customRenderers.renderValue || renderValue )( path[ path.length - 1 ], data, val, container, path, context );
    }
    return container;
}

/**
 * The library provided renderers.
 * @typedef {Object} DefaultRenderers
 * @property {Function} renderNode The library code render node implementation, see {@link renderNode}
 * @property {Function} endRenderNode The library code end render node implementation, see {@link endRenderNode}
 * @property {Function} renderValue The library code value type render implementation, see {@link renderValue} and {@link extractValue}
*/
render.defaultRenderers = {
    renderNode: renderNode,
    endRenderNode: endRenderNode,
    renderValue: renderValue
}

module.exports = render;

},{"jquery":2,"jsdom":2}],2:[function(require,module,exports){

},{}]},{},[1])(1)
});